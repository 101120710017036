/** @jsx jsx this comment is required for Storybook to render */
import * as React from 'react';
import PropTypes from 'prop-types';
import { css, jsx } from '@emotion/core';

import { pageDimensions, unitConverter as uc } from '../../../styles/base';
import PageWrapper from '../PageWrapper/PageWrapper';

const ResponsiveContentWrapper = ({ children, className }) => {
  // adding 40 pixels to the breakpoint allows
  // the content to shrink to accommodate
  // shadows as the screen approaches rawDesktopWidth
  const breakpointWidth = pageDimensions.rawDesktopWidth + 40;
  const respContentWrapperCss = css`
    position: relative;
    display: block;
    width: auto;

    @media (max-width: ${uc(`${breakpointWidth}px`)}) {
      margin: 0 ${uc('20px')};
    }
  `;

  return (
    <PageWrapper css={respContentWrapperCss} className={className}>
      <React.Fragment>{children}</React.Fragment>
    </PageWrapper>
  );
};

ResponsiveContentWrapper.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
};

ResponsiveContentWrapper.defaultProps = {
  className: '',
  children: null,
};

export default ResponsiveContentWrapper;
