import PropTypes from 'prop-types';
/** @jsx jsx this comment is required for Storybook to render */
import { css, jsx } from '@emotion/core';
import {
  colors,
  fontFamilies,
  fontSizes,
  fontWeights,
  unitConverter as uc,
} from '../../../styles/base';

const BasicIcon = ({ bgColor, className, color, text }) => {
  const basicIconCss = css`
    display: inline-block;
    width: ${uc('48px')};
    min-width: ${uc('48px')};
    height: ${uc('48px')};
    color: ${color};
    font-weight: ${fontWeights.semiBold};
    font-size: ${fontSizes.twentyThree};
    font-family: ${fontFamilies.proximaSoft};
    line-height: ${uc('50px')};
    text-align: center;
    background: ${bgColor};
    border-radius: 50%;
  `;

  return (
    <span css={basicIconCss} className={className}>
      {text}
    </span>
  );
};

BasicIcon.propTypes = {
  bgColor: PropTypes.string,
  color: PropTypes.string,
  text: PropTypes.string,
  className: PropTypes.string,
};

BasicIcon.defaultProps = {
  bgColor: colors.white,
  color: colors.primary,
  text: '?',
  className: '',
};

export const QuestionIcon = ({ className }) => {
  return <BasicIcon className={className} />;
};

QuestionIcon.propTypes = {
  className: BasicIcon.propTypes.className,
};

QuestionIcon.defaultProps = {
  className: BasicIcon.defaultProps.className,
};

export const ErrorIcon = ({ className }) => {
  return (
    <BasicIcon
      color={colors.white}
      bgColor={colors.accent}
      text="!"
      className={className}
    />
  );
};

ErrorIcon.propTypes = {
  ...BasicIcon.propTypes,
};

ErrorIcon.defaultProps = {
  ...BasicIcon.defaultProps,
};

export default { QuestionIcon, ErrorIcon };
