/**
 * Adds a Form with side text and blurb underneath
 */

import PropTypes from 'prop-types';
/** @jsx jsx this comment is required for Storybook to render */
import { css, jsx } from '@emotion/core';
import { graphql, useStaticQuery } from 'gatsby';
import { letterSpacing } from 'styled-system';
import { useContext } from 'react';
import { QuestionIcon } from '../../atoms/Icon/BasicIcon';
import BlockContent from '../../molecules/BlockContentSection/BlockContentSection';
import Form from '../../molecules/Form/Form';
import MarketoEmbeddedForm from '../../molecules/MarketoEmbeddedForm/MarketoEmbeddedForm';
import { Heading3 } from '../../atoms/Headings/Headings';
import Section from '../../molecules/Section/Section';
import ResponsiveContentWrapper from '../ResponsiveContentWrapper/ResponsiveContentWrapper';

import {
  breakpoints,
  colors,
  fontSizes,
  fontWeights,
  sectionSettings,
  unitConverter as uc,
} from '../../../styles/base';
import useLocalize from '../../../hooks/useLocalize';
import { LocaleContext } from '../../../context/LocaleContext';

const FormSection = ({ description, form, formPosition, sectionStyles }) => {
  const rawData = useStaticQuery(graphql`
    {
      sanityFormBlurbReference(blurbId: { eq: "weHaveAnswers" }) {
        _rawBlurbBody(resolveReferences: { maxDepth: 10 })
        blurbHeading: _rawBlurbHeading(resolveReferences: { maxDepth: 10 })
        blurbId
      }
    }
  `) || { sanityFormBlurbReference: {} };
  const { locale } = useContext(LocaleContext);
  const data = useLocalize(rawData, locale);
  const formSectionCss = css`
    display: flex;
    height: 100%;
    min-height: ${uc('900px')};
    margin: 0 auto;
    background-color: ${sectionStyles && sectionStyles.backgroundColor
      ? sectionSettings[sectionStyles.backgroundColor]
      : colors.white};
    background-size: contain;

    @media (${breakpoints.tablet}) {
      min-height: ${uc('1300px')};
    }
  `;

  const backgroundCss = css`
    position: absolute;
    top: ${uc('140px')};
    z-index: -1;
    display: flex;
    width: 100%;
    height: 120%;
    background: ${colors.primary};
    transform: skewY(-10deg);

    @media (${breakpoints.tablet}) {
      transform: skewY(-10deg);
    }
  `;

  const pageWrapperCss = css`
    display: flex;
    margin: 0 auto;

    @media (${breakpoints.tablet}) {
      justify-content: center;
      width: 100%;
    }

    @media (${breakpoints.mobile}) {
      width: 100%;

      > div {
        width: 100%;
      }
    }
  `;

  const formCss = css`
    width: ${uc('920px')};
    max-width: ${uc('920px')};
    margin-top: ${uc('-100px')};

    @media (${breakpoints.smallDesktopMax}) {
      width: ${uc('700px')};
    }

    @media (${breakpoints.largeTablet}) {
      width: 100%;
    }

    @media (${breakpoints.mobile}) {
      width: 90%;
    }
  `;

  const formSectionTextCss = css`
    h3,
    span,
    p,
    li,
    a {
      color: ${colors.white};
    }

    a {
      text-decoration: underline;
    }
  `;

  const blurbCss = css`
    margin: ${uc('40px 0 60px 20px')};

    @media (${breakpoints.mobile}) {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: ${uc('40px 0 60px')};
      text-align: center;
    }
  `;

  const questionIconCss = css`
    display: inline-block;
    vertical-align: top;
  `;

  const blurbTextContainerCss = css`
    display: inline-block;
    margin: ${uc('5px 0 0 20px')};
    vertical-align: top;

    @media (${breakpoints.mobile}) {
      margin-left: 0;
    }
  `;

  const blurbHeadingCss = css`
    margin-bottom: ${uc('1px')};
    color: ${colors.white};
    font-weight: ${fontWeights.light};
    font-size: ${fontSizes.thirty};
    letter-spacing: ${letterSpacing.normal};

    @media (${breakpoints.mobile}) {
      margin-bottom: 0;
    }
  `;

  const blurbDescriptionCss = css`
    ${formSectionTextCss}
    max-width: ${uc('400px')};

    > div {
      margin-left: 0;
      padding: 0;
    }

    @media (${breakpoints.smallDesktopMax}) {
      margin-top: 0;

      > div {
        margin-top: 0;
      }
    }

    @media (${breakpoints.mobile}) {
      max-width: ${uc('260px')};
      margin: auto;

      span {
        font-size: ${fontSizes.eighteen};
        letter-spacing: normal;
      }
    }
  `;

  const descriptionCss = css`
    ${formSectionTextCss}
    margin-top: ${formPosition === 'left' ? '10vh' : 'auto'};
    margin-left: ${formPosition === 'left' ? uc('20px') : 'auto'};

    h4 {
      font-weight: ${fontWeights.light};
      font-size: ${fontSizes.thirty};
      letter-spacing: ${letterSpacing.normal};
    }

    @media (${breakpoints.tablet}) {
      display: none;
    }
  `;

  const renderForm = ([formToRender]) => {
    if (!formToRender) {
      return null;
    }

    switch (formToRender._type) {
      case 'form':
        return <Form {...formToRender} css={formCss} />;
      case 'marketoEmbeddedForm':
        return <MarketoEmbeddedForm {...formToRender} css={formCss} />;
      default:
        return null;
    }
  };

  return (
    <Section css={formSectionCss} sectionStyles={sectionStyles}>
      <div css={backgroundCss} />
      <ResponsiveContentWrapper css={pageWrapperCss}>
        {formPosition === 'right' && description && description.length > 0 && (
          <BlockContent blockCss={descriptionCss} content={description} />
        )}
        <div>
          {renderForm(form)}
          <div css={blurbCss}>
            <QuestionIcon css={questionIconCss} />
            <div css={blurbTextContainerCss}>
              <Heading3 css={blurbHeadingCss}>{data.blurbHeading}</Heading3>
              {data.blurbBody && (
                <BlockContent
                  blockCss={blurbDescriptionCss}
                  content={data.blurbBody}
                  inSidebar
                />
              )}
            </div>
          </div>
        </div>
        {formPosition === 'left' && description && description.length > 0 && (
          <BlockContent blockCss={descriptionCss} content={description} />
        )}
      </ResponsiveContentWrapper>
    </Section>
  );
};

/**
 * Prop types
 */
FormSection.propTypes = {
  description: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.shape({})),
    PropTypes.shape({}),
  ]),
  form: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  formPosition: PropTypes.string,
  sectionStyles: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.shape({})),
    PropTypes.shape({}),
  ]),
};

FormSection.defaultProps = {
  description: {},
  formPosition: 'left',
  sectionStyles: {},
};

export default FormSection;
